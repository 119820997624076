.footer{
    width: 100%;
    padding: 6rem 0;
    background-color: var(--darkgray)
}

.footer>hr{
    border: 1px solid var(--caloryCard);
}

.footer-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

.left{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.location{
    display: flex;
    margin-bottom: 0.8rem;
}

.location p{
    line-height: 30px;
    color: white;
}

.right{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.right>p{
    color: white;
}

.social{
    margin-top: 1rem;
}

h4{
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
    color: white;
}

p{
    font-size: 1.2rem;
}

.blur-f-1{
    bottom: -175rem;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background-color: rgb(255, 115, 0);
}

.blur-f-2{
    bottom: -175rem;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background-color: rgb(217, 196, 38);
}
/* .footer>:nth-child(2) {
    bottom: 0;
    -webkit-filter: blur(200px);
    filter: blur(200px);
    height: 12rem;
    width: 26rem;
} */


@media screen and (max-width: 640px) {
    .footer-container{
        grid-template-columns: 1fr;
    }
}